import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Alert, Spin, Card, Descriptions, AutoComplete } from 'antd';
import QRCodeScanner from '../components/QRCodeScanner';
import apiClient from '../api/apiClient';
import { isMobileDevice } from '../utils/isMobileDevice';
import Header from '../components/Header';

function ReceivePalletPage() {
  const [form] = Form.useForm();
  const [showScanner, setShowScanner] = useState(false);
  const [palletInfo, setPalletInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  // Новые состояния для автодополнения
  const [pallets, setPallets] = useState([]);
  const [palletOptions, setPalletOptions] = useState([]);

  // Новое состояние для валидности формы
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (isMobileDevice()) {
      setShowScanner(true);
    }
  }, []);

  // Обновление валидности формы
  const updateFormValidity = () => {
    const fieldsError = form.getFieldsError();
    const hasErrors = fieldsError.some(({ errors }) => errors.length);
    const fieldsValue = form.getFieldsValue();
    const requiredFields = ['qr_code'];
    const allFieldsFilled = requiredFields.every(
      (field) => fieldsValue[field] !== undefined && fieldsValue[field] !== ''
    );
    setIsFormValid(!hasErrors && allFieldsFilled && !!palletInfo);
  };

  useEffect(() => {
    updateFormValidity();
  }, [palletInfo]);

  const handleScanResult = async (qrCode) => {
    form.setFieldsValue({ qr_code: qrCode });
    setShowScanner(false);
    await handleQRCodeChange(qrCode);
  };

  const handleScanError = (error) => {
    notification.error({ message: 'Ошибка сканирования', description: error });
    setShowScanner(false);
  };

  const handleQRCodeChange = async (value) => {
    const qrCode = value.trim();
    const qrCodeRegex = /^\d{4}-\d-\d{1,4}-\d{10}$/;

    // Очищаем предыдущие данные при изменении QR-кода
    setPalletInfo(null);
    form.setFieldsValue({ qr_code: qrCode });

    if (qrCodeRegex.test(qrCode)) {
      try {
        setLoading(true);
        const response = await apiClient.get(`/pallet_info/?qr_code=${qrCode}`);
        const pallet = response.data;
        setPalletInfo(pallet);
        updateFormValidity();
      } catch (error) {
        notification.error({
          message: 'Ошибка при получении информации о палете',
          description: error.response?.data?.error || 'Неизвестная ошибка',
        });
        setPalletInfo(null);
      } finally {
        setLoading(false);
      }
    }
  };

  // Обработчик поиска палет
  const handleQRCodeSearch = async (value) => {
    const trimmedValue = value.trim();

    // Очищаем предыдущие данные при изменении QR-кода
    setPalletInfo(null);

    // Если введено 4 цифры
    if (/^\d{4}$/.test(trimmedValue)) {
      try {
        setLoading(true);
        const response = await apiClient.get(`/all_pallets/?code=${trimmedValue}&on_warehouse=False`);
        const fetchedPallets = response.data.pallets;

        setPallets(fetchedPallets);

        setPalletOptions(
          fetchedPallets.map((pallet) => ({
            value: pallet.qr_code,
            label: `${pallet.qr_code} Кол-во: ${pallet.quantity}`,
          }))
        );
      } catch (error) {
        notification.error({
          message: 'Ошибка при получении палет',
          description: error.response?.data?.error || 'Неизвестная ошибка',
        });
        setPalletOptions([]);
      } finally {
        setLoading(false);
      }
    } else {
      setPalletOptions([]);
    }
  };

  // Обработчик выбора палеты из списка
  const handleQRCodeSelect = async (value) => {
    form.setFieldsValue({ qr_code: value });

    const selectedPallet = pallets.find((pallet) => pallet.qr_code === value);
    if (selectedPallet) {
      setPalletInfo(selectedPallet);
      updateFormValidity();
    } else {
      await handleQRCodeChange(value);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await apiClient.post('/receive_pallet/', values);
      notification.success({ message: 'Палет успешно принят на склад' });
      form.resetFields();
      setPalletInfo(null);
      updateFormValidity();
    } catch (error) {
      notification.error({
        message: 'Ошибка при приемке палета',
        description: error.response?.data?.error || 'Неизвестная ошибка',
      });
    } finally {
      setLoading(false);
    }
  };

  // Определяем сообщение предупреждения на основе palletInfo
  const renderAlert = () => {
    if (!palletInfo) return null;

    if (palletInfo.quantity > 0) {
      return (
        <Alert
          message={`Палет содержит ${palletInfo.quantity} единиц товара.`}
          type="info"
          showIcon
          style={{ marginTop: '20px' }}
        />
      );
    } else {
      return (
        <Alert
          message="Палета уже была отгружена."
          type="error"
          showIcon
          style={{ marginTop: '20px' }}
        />
      );
    }
  };

  // Функция для условного отображения полей
  const renderDescriptions = () => {
    if (!palletInfo) return null;

    const fields = [
      { label: 'ID Палеты', value: palletInfo.id },
      { label: 'QR-код', value: palletInfo.qr_code },
      { label: 'Продукт', value: palletInfo.product?.name },
      { label: 'Количество', value: palletInfo.quantity },
      { label: 'Размер', value: `${palletInfo.size} м.` },
      { label: 'Упаковщик', value: palletInfo.packing_by },
      { label: 'Дата упаковки', value: palletInfo.packing_date ? new Date(palletInfo.packing_date).toLocaleString() : null },
      { label: 'Дата приемки', value: palletInfo.receiving_date ? new Date(palletInfo.receiving_date).toLocaleString() : null },
      { label: 'Приемщик', value: palletInfo.receiving_by },
      { label: 'Дата отправки', value: palletInfo.sending_date ? new Date(palletInfo.sending_date).toLocaleString() : null },
      { label: 'Отправитель', value: palletInfo.sending_by },
      { label: 'На складе', value: palletInfo.on_warehouse ? 'Да' : 'Нет' },
    ];

    return (
      <Card id="printableContent" title="Информация о палете" style={printableContentStyle}>
        <Descriptions bordered column={1}>
          {fields.map((field, index) => (
            field.value !== null && field.value !== undefined && field.value !== '' ? (
              <Descriptions.Item key={index} label={field.label}>
                {field.value}
              </Descriptions.Item>
            ) : null
          ))}
        </Descriptions>
      </Card>
    );
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <h2>Принять палет</h2>
        <Form
          form={form}
          onFinish={onFinish}
          onFieldsChange={updateFormValidity}
          layout="vertical"
        >
          <Form.Item
            label="QR-код палета"
            name="qr_code"
            rules={[
              { required: true, message: 'Введите QR-код палеты' },
              {
                pattern: /^\d{4}-\d-\d{1,4}-\d{10}$/,
                message: 'Неверный формат QR-кода',
              },
            ]}
          >
            <AutoComplete
              onSearch={handleQRCodeSearch}
              onSelect={handleQRCodeSelect}
              onChange={handleQRCodeChange}
              options={palletOptions}
              filterOption={false}
            >
              <Input
                placeholder="Сканируйте или введите QR-код или 4-значный код"
                maxLength={22}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!isFormValid || loading}>
              Принять палет
            </Button>
            {!showScanner && (
              <Button
                type="default"
                onClick={() => setShowScanner(true)}
                style={{ marginLeft: '10px' }}
              >
                Сканировать QR-код
              </Button>
            )}
          </Form.Item>
        </Form>
        {/* Отображение предупреждений */}
        {renderAlert()}
        {showScanner && (
          <div style={{ marginTop: '20px' }}>
            <QRCodeScanner onResult={handleScanResult} onError={handleScanError} />
            <Button
              type="default"
              onClick={() => setShowScanner(false)}
              style={{ marginTop: '10px' }}
            >
              Закрыть камеру
            </Button>
          </div>
        )}

        {/* Отображение информации о палете после успешного получения */}
        {palletInfo && (
          <div>
            {renderDescriptions()}
          </div>
        )}

        {/* Индикатор загрузки */}
        {loading && (
          <div style={spinContainerStyle}>
            <Spin tip="Загрузка..." />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReceivePalletPage;

// Стили для контейнера
const containerStyle = {
  maxWidth: 800,
  margin: '50px auto',
  padding: '0 20px',
};

// Стили для printableContent
const printableContentStyle = {
  width: '100%',
  marginTop: '20px',
};

// Стили для индикатора загрузки
const spinContainerStyle = {
  textAlign: 'center',
  marginTop: '20px',
};