import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Radio, Spin, AutoComplete } from 'antd';
import apiClient from '../api/apiClient';
import { QRCodeCanvas } from 'qrcode.react';
import Header from '../components/Header';
import printJS from 'print-js';

function AddPalletPage() {
  const [form] = Form.useForm();
  const [qrCode, setQrCode] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const sizes = [3, 4, 6];

  // Новые состояния для автодополнения
  const [workers, setWorkers] = useState([]);
  const [workerOptions, setWorkerOptions] = useState([]);

  // Новые состояния для валидации формы
  const [isFormValid, setIsFormValid] = useState(false);

  // Состояния для сгенерированного QR-кода и информации о продукте
  const [generatedQrCode, setGeneratedQrCode] = useState(null);
  const [generatedProductInfo, setGeneratedProductInfo] = useState(null);

  // Обновление валидности формы
  const updateFormValidity = () => {
    const fieldsError = form.getFieldsError();
    const hasErrors = fieldsError.some(({ errors }) => errors.length);
    const fieldsValue = form.getFieldsValue();
    const requiredFields = ['code', 'size', 'packing_by', 'quantity'];
    const allFieldsFilled = requiredFields.every(
      (field) => fieldsValue[field] !== undefined && fieldsValue[field] !== ''
    );
    setIsFormValid(!hasErrors && allFieldsFilled);
  };

  useEffect(() => {
    updateFormValidity();

    // Получение списка работников
    const fetchWorkers = async () => {
      try {
        const response = await apiClient.get('/all_workers/');
        setWorkers(response.data.workers);
      } catch (error) {
        console.error('Ошибка при получении списка работников:', error);
      }
    };

    fetchWorkers();
  }, []);

  // Обработчик изменения кода продукта
  const handleCodeChange = async (e) => {
    const code = e.target.value.trim();
    if (code && code.length === 4) {
      try {
        setLoading(true);
        const response = await apiClient.get(`/product_info/?code=${code}`);
        const product = response.data;

        setProductInfo(product);

        const {
          default_quantity_3m,
          default_quantity_4m,
          default_quantity_6m,
        } = product;

        if (
          default_quantity_3m === default_quantity_4m &&
          default_quantity_3m === default_quantity_6m &&
          default_quantity_3m !== 0
        ) {
          // Все стандартные количества равны и не равны нулю
          form.setFieldsValue({ quantity: default_quantity_3m });
        } else if (
          default_quantity_3m === 0 &&
          default_quantity_4m === 0 &&
          default_quantity_6m === 0
        ) {
          // Все стандартные количества равны нулю, не заполняем
          form.setFieldsValue({ quantity: null });
        } else {
          // Стандартные количества различаются, ждем выбора размера
          form.setFieldsValue({ quantity: null });
        }
      } catch (error) {
        setProductInfo(null);
        form.setFieldsValue({ quantity: null });
        notification.error({
          message: 'Продукт не найден',
          description: `Продукт с кодом "${code}" не найден.`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setProductInfo(null);
      form.setFieldsValue({ quantity: null });
    }
  };

  // Обработчик изменения размера
  const handleSizeChange = (e) => {
    const size = e.target.value;
    if (productInfo) {
      let defaultQuantity = null;
      if (size === 3) {
        defaultQuantity = productInfo.default_quantity_3m;
      } else if (size === 4) {
        defaultQuantity = productInfo.default_quantity_4m;
      } else if (size === 6) {
        defaultQuantity = productInfo.default_quantity_6m;
      }
      if (defaultQuantity !== 0) {
        form.setFieldsValue({ quantity: defaultQuantity });
      } else {
        form.setFieldsValue({ quantity: null });
      }
    }
  };

  // Обработчик поиска работников
  const handleWorkerSearch = (value) => {
    if (value) {
      // Создаем регулярное выражение:
      // (?:^|\s) - начало строки или пробел
      // ${value} - искомое значение
      const regex = new RegExp(`(?:^|\\s)${value.toLowerCase()}`, 'i');
      
      const filteredWorkers = workers.filter((worker) =>
        regex.test(worker.name.toLowerCase())
      );
      
      setWorkerOptions(
        filteredWorkers.map((worker) => ({ value: worker.name }))
      );
    } else {
      setWorkerOptions([]);
    }
  };

  // Обработчик выбора работника из списка
  const handleWorkerSelect = (value) => {
    form.setFieldsValue({ packing_by: value });
  };

  // Обработчик отправки формы
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await apiClient.post('/add_pallet/', values);
      setGeneratedQrCode(response.data.qr_code);
      setGeneratedProductInfo(productInfo); // Сохраняем текущую информацию о продукте
      notification.success({ message: 'Палет успешно добавлен' });
    } catch (error) {
      notification.error({
        message: 'Ошибка при добавлении палета',
        description: error.response?.data?.error || 'Неизвестная ошибка',
      });
    } finally {
      setLoading(false);
    }
  };

  // Функция для печати с использованием printJS
  const handlePrint = () => {
    printJS({
      printable: 'printableContent',
      type: 'html',
      targetStyles: ['*'],
      style: `
        @page { margin: 0; }
        body { margin: 0; padding: 0; }
        #printableContent {
          box-shadow: none !important;
          margin: 0 !important;
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          width: auto !important;
        }
      `,
    });
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <h2>Добавить палет</h2>
        <Form
          form={form}
          onFinish={onFinish}
          onFieldsChange={updateFormValidity}
          layout="vertical"
        >
          {/* Поле ввода кода продукта */}
          <Form.Item
            label="Код продукта"
            name="code"
            rules={[{ required: true, message: 'Введите код продукта' }]}
          >
            <Input onChange={handleCodeChange} maxLength={4} />
          </Form.Item>

          {/* Отображение информации о продукте после ввода кода */}
          {productInfo && (
            <div style={productInfoStyle}>
              <p>
                <strong>Название:</strong> {productInfo.name}
              </p>
              
              {/* Проверяем, что ни одно из стандартных количеств не равно нулю */}
              {productInfo.default_quantity_3m !== 0 &&
              productInfo.default_quantity_4m !== 0 &&
              productInfo.default_quantity_6m !== 0 && (
                <>
                  {/* Проверяем, что все стандартные количества равны */}
                  {productInfo.default_quantity_3m === productInfo.default_quantity_4m &&
                  productInfo.default_quantity_4m === productInfo.default_quantity_6m ? (
                    <p>
                      <strong>Стандартное количество:</strong> {productInfo.default_quantity_3m}
                    </p>
                  ) : (
                    <>
                      <p>
                        <strong>Стандартное количество 3м:</strong> {productInfo.default_quantity_3m}
                      </p>
                      <p>
                        <strong>Стандартное количество 4м:</strong> {productInfo.default_quantity_4m}
                      </p>
                      <p>
                        <strong>Стандартное количество 6м:</strong> {productInfo.default_quantity_6m}
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {/* Выбор размера продукта */}
          <Form.Item
            label="Размер продукта (м)"
            name="size"
            rules={[{ required: true, message: 'Выберите размер продукта' }]}
          >
            <Radio.Group onChange={handleSizeChange}>
              {sizes.map((size) => (
                <Radio.Button key={size} value={size}>
                  {size}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>

          {/* Поле ввода имени упаковщика с автодополнением */}
          <Form.Item
            label="Имя упаковщика"
            name="packing_by"
            rules={[{ required: true, message: 'Введите имя упаковщика' }]}
          >
            <AutoComplete
              onSearch={handleWorkerSearch}
              onSelect={handleWorkerSelect}
              options={workerOptions}
              filterOption={false}
            >
              <Input />
            </AutoComplete>
          </Form.Item>

          {/* Поле ввода количества */}
          <Form.Item
            label="Количество"
            name="quantity"
            rules={[{ required: true, message: 'Введите количество' }]}
          >
            <Input type="number" min={1} />
          </Form.Item>

          {/* Кнопка отправки формы */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isFormValid || loading}
            >
              Добавить палет
            </Button>
          </Form.Item>
        </Form>

        {/* Отображение QR-кода и кнопки печати после успешного добавления палета */}
        {generatedQrCode && (
          <div
            id="printableContent"
            className="printable-content"
            style={printableContentStyle}
          >
            <h2 style={productNameStyle}>
              {generatedProductInfo && generatedProductInfo.name}
            </h2>
            <QRCodeCanvas value={generatedQrCode} size={236} />
            <p style={qrCodeTextStyle}>{generatedQrCode}</p>
          </div>
        )}
        {generatedQrCode && (
          <Button
            type="primary"
            onClick={handlePrint}
            style={printButtonStyle}
            disabled={loading}
          >
            Печать
          </Button>
        )}

        {/* Индикатор загрузки */}
        {loading && (
          <div style={spinContainerStyle}>
            <Spin tip="Загрузка..." />
          </div>
        )}
      </div>

      {/* Встроенные стили для медиа-запросов (можно удалить, так как стили передаются через printJS) */}
      <style>{`
        .printable-content {
          box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
        }
        @media print {
          .printable-content {
            box-shadow: none !important;
          }
          body {
            margin: 0;
          }
        }
      `}</style>
    </div>
  );
}

export default AddPalletPage;

// Стили для контейнера
const containerStyle = {
  maxWidth: 600,
  margin: '50px auto',
  padding: '0 20px',
};

// Стили для printableContent
const printableContentStyle = {
  width: '256px',
  textAlign: 'center',
  padding: '10px',
  backgroundColor: 'white',
  borderRadius: '8px',
  margin: '20px auto', // Центрируем контейнер на экране
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

// Стили для блока информации о продукте
const productInfoStyle = {
  backgroundColor: '#f5f5f5',
  padding: '10px',
  marginTop: '10px',
  borderRadius: '4px',
};

// Стили для названия продукта
const productNameStyle = {
  fontSize: '16px',
  marginBottom: '10px',
};

// Стили для текста QR-кода
const qrCodeTextStyle = {
  wordWrap: 'break-word',
  marginTop: '10px',
  fontSize: '18px',
};

// Стили для кнопки печати
const printButtonStyle = {
  marginTop: '10px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
};

// Стили для индикатора загрузки
const spinContainerStyle = {
  textAlign: 'center',
  marginTop: '20px',
};