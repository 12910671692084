// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Новая точка импорта
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'antd/dist/antd.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  // Оберните в StrictMode, если необходимо
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);