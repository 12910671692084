// src/components/PalletList.js

import React, { useEffect, useState } from 'react';
import { Modal, Table, Tabs, Spin, notification } from 'antd';
import apiClient from '../api/apiClient';

const { TabPane } = Tabs;

function PalletList({ code, size, onClose }) {
  const [pallets, setPallets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPallet, setSelectedPallet] = useState(null);
  const [operationHistory, setOperationHistory] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('1'); // Добавлено состояние для активной вкладки

  useEffect(() => {
    const fetchPallets = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get('/pallets_by_product/', {
          params: { code, size },
        });
        setPallets(response.data.pallets);
      } catch (error) {
        notification.error({
          message: 'Ошибка при получении палет',
          description: error.response?.data?.error || 'Неизвестная ошибка',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPallets();
  }, [code, size]);

  const handleRowClick = async (record) => {
    try {
      setLoading(true);
      const response = await apiClient.get('/pallet_operation_history/', {
        params: { qr_code: record.qr_code },
      });
      setSelectedPallet(record);
      setOperationHistory(response.data.operations);
      setActiveTabKey('2'); // Автоматически переключаемся на вкладку истории операций
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении истории операций',
        description: error.response?.data?.error || 'Неизвестная ошибка',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const palletColumns = [
    {
      title: 'QR-код',
      dataIndex: 'qr_code',
      key: 'qr_code',
    },
    {
      title: 'Количество',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Размер',
      dataIndex: 'size',
      key: 'size',
      render: (text) => `${text} м.`,
    },
    {
      title: 'Упаковщик',
      dataIndex: 'packing_by',
      key: 'packing_by',
    },
    {
      title: 'Дата упаковки',
      dataIndex: 'packing_date',
      key: 'packing_date',
      render: (text) => (text ? new Date(text).toLocaleString() : ''),
    },
  ];

  const operationColumns = [
    {
      title: 'Тип операции',
      dataIndex: 'operation_type',
      key: 'operation_type',
    },
    {
      title: 'Изменение количества',
      dataIndex: 'quantity_changed',
      key: 'quantity_changed',
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Выполнил',
      dataIndex: 'performed_by',
      key: 'performed_by',
    },
  ];

  return (
    <Modal
      visible={true}
      title={`Палеты продукта ${code}, размер ${size} м.`}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      {loading ? (
        <Spin tip="Загрузка..." />
      ) : (
        <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
          <TabPane tab="Список палет" key="1">
            <Table
              dataSource={pallets}
              columns={palletColumns}
              rowKey="qr_code"
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                style: { cursor: 'pointer' },
              })}
              pagination={false}
            />
          </TabPane>
          {selectedPallet && (
            <TabPane
              tab={`История операций: ${selectedPallet.qr_code}`}
              key="2"
            >
              <Table
                dataSource={operationHistory}
                columns={operationColumns}
                rowKey="id"
                pagination={false}
              />
            </TabPane>
          )}
        </Tabs>
      )}
    </Modal>
  );
}

export default PalletList;
