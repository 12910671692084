// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import AddPalletPage from './pages/AddPalletPage';
import ReceivePalletPage from './pages/ReceivePalletPage';
import SendPalletPage from './pages/SendPalletPage';
import RefillPalletPage from './pages/RefillPalletPage';
import PrivateRoute from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/add-pallet"
          element={
            <PrivateRoute>
              <AddPalletPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/receive-pallet"
          element={
            <PrivateRoute>
              <ReceivePalletPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/send-pallet"
          element={
            <PrivateRoute>
              <SendPalletPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/refill-pallet"
          element={
            <PrivateRoute>
              <RefillPalletPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;