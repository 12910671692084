// src/api/apiClient.js

import axios from 'axios';
import config from '../config'; // Импортируем конфигурацию

const apiClient = axios.create({
  baseURL: config.API_BASE_URL,
});

// Добавляем интерцептор для добавления JWT-токена к каждому запросу
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Обработка ответов с ошибками
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refresh_token');

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true;

      try {
        // Получаем новый access token
        const response = await axios.post(`${config.API_BASE_URL}/token/refresh/`, {
          refresh: refreshToken,
        });

        const { access } = response.data;

        // Обновляем токены в localStorage
        localStorage.setItem('access_token', access);

        // Обновляем заголовок Authorization и повторяем запрос
        originalRequest.headers['Authorization'] = 'Bearer ' + access;
        return apiClient(originalRequest);
      } catch (err) {
        // Если обновить токен не удалось, выходим из системы
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        // Перенаправить пользователя на страницу входа, если необходимо
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;