// src/components/QRCodeScanner.js

import React, { useEffect, useRef } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

function QRCodeScanner({ onResult, onError }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    codeReader
      .decodeFromVideoDevice(null, videoRef.current, (result, err) => {
        if (result) {
          onResult(result.text);
          codeReader.reset();
        }
        if (err && !(err instanceof NotFoundException)) {
          onError(err.message || 'Ошибка при сканировании');
          codeReader.reset();
        }
      })
      .catch((err) => {
        if (err.name === 'NotAllowedError') {
          onError('Доступ к камере запрещен. Пожалуйста, предоставьте разрешение.');
        } else {
          onError(err.message || 'Ошибка при доступе к камере');
        }
      });

    return () => {
      codeReader.reset();
    };
  }, [onResult, onError]);

  const videoStyle = {
    width: '100%',
    maxWidth: '100%',
    borderRadius: '8px',
  };

  return (
    <div>
      <video ref={videoRef} style={videoStyle} />
    </div>
  );
}

export default QRCodeScanner;