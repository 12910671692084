// src/components/Header.js

import React, { useState } from 'react';
import { Menu, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/slices/authSlice';

function Header() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const menuItems = [
    { key: 'home', label: <Link to="/">Главная</Link> },
    { key: 'add-pallet', label: <Link to="/add-pallet">Добавить палет</Link> },
    { key: 'receive-pallet', label: <Link to="/receive-pallet">Принять палет</Link> },
    { key: 'send-pallet', label: <Link to="/send-pallet">Отправить палет</Link> },
    { key: 'refill-pallet', label: <Link to="/refill-pallet">Пополнить палет</Link> },
  ];

  const isMobile = window.innerWidth <= 768;

  return (
    <div style={{ backgroundColor: '#001529', padding: '0 20px' }}>
      {!isMobile && (
        <Menu
        
          theme="dark"
          mode="horizontal"
          selectable={false}
          style={{ lineHeight: '64px' }}
        >
          {menuItems.map((item) => (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          ))}
          <Menu.Item key="user" style={{ marginLeft: 'auto' }}>
            {user && (
              <span style={{ color: '#fff', marginRight: '10px' }}>
                Вы вошли как: {user.username}
              </span>
            )}
            <Button type="primary" onClick={handleLogout}>
              Выйти
            </Button>
          </Menu.Item>
        </Menu>
      )}
      {isMobile && (
        <div style={{ display: 'flex', alignItems: 'center', height: '64px' }}>
          <Button type="primary" onClick={showDrawer} icon={<MenuOutlined />} />
          <div style={{ flexGrow: 1, textAlign: 'center', color: '#fff', fontSize: '18px' }}>
            {user ? `Вы вошли как: ${user.username}` : 'Палет Менеджмент'}
          </div>
        </div>
      )}
      <Drawer
        title="Меню"
        placement="left"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <Menu mode="inline" selectable={false} onClick={onClose}>
          {menuItems.map((item) => (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          ))}
          <Menu.Item key="logout">
            <Button type="primary" onClick={handleLogout}>
              Выйти
            </Button>
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
}

export default Header;