// src/pages/HomePage.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Spin, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/slices/authSlice';
import Header from '../components/Header';
import apiClient from '../api/apiClient';
import PalletList from '../components/PalletList';
import './HomePage.css'; // Импортируем CSS файл

function HomePage() {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);

  const [productSummary, setProductSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPallets, setSelectedPallets] = useState(null);

  const handleLogout = () => {
    dispatch(logout());
  };

  const containerStyle = {
    padding: '20px',
    textAlign: 'center',
  };

  const buttonStyle = {
    margin: '10px',
    width: '200px',
  };

  useEffect(() => {
    const fetchProductSummary = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get('/products_summary/');
        setProductSummary(response.data);
      } catch (error) {
        notification.error({
          message: 'Ошибка при получении сводки по продуктам',
          description: error.response?.data?.error || 'Неизвестная ошибка',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProductSummary();
  }, [token]);

  const handlePalletCellClick = (record, size) => {
    setSelectedPallets({ code: record.code, size });
  };

  const columns = [
    {
      title: 'Товар',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Количество 3м',
      dataIndex: 'quantity_3m',
      key: 'quantity_3m',
    },
    {
      title: 'Количество 4м',
      dataIndex: 'quantity_4m',
      key: 'quantity_4m',
    },
    {
      title: 'Количество 6м',
      dataIndex: 'quantity_6m',
      key: 'quantity_6m',
    },
    {
      title: 'Палет 3м',
      dataIndex: 'pallets_3m',
      key: 'pallets_3m',
      onCell: (record) => ({
        onClick: () => handlePalletCellClick(record, 3),
        className: 'clickable-cell',
      }),
    },
    {
      title: 'Палет 4м',
      dataIndex: 'pallets_4m',
      key: 'pallets_4m',
      onCell: (record) => ({
        onClick: () => handlePalletCellClick(record, 4),
        className: 'clickable-cell',
      }),
    },
    {
      title: 'Палет 6м',
      dataIndex: 'pallets_6m',
      key: 'pallets_6m',
      onCell: (record) => ({
        onClick: () => handlePalletCellClick(record, 6),
        className: 'clickable-cell',
      }),
    },
    {
      title: 'Общее количество',
      dataIndex: 'total_quantity',
      key: 'total_quantity',
    },
  ];

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <p>Выберите действие:</p>
        <div style={{ marginTop: '20px' }}>
          <Link to="/add-pallet">
            <Button type="primary" size="large" style={buttonStyle}>
              Добавить палет
            </Button>
          </Link>
          <Link to="/receive-pallet">
            <Button type="primary" size="large" style={buttonStyle}>
              Принять палет
            </Button>
          </Link>
          <Link to="/send-pallet">
            <Button type="primary" size="large" style={buttonStyle}>
              Отправить палет
            </Button>
          </Link>
          <Link to="/refill-pallet">
            <Button type="primary" size="large" style={buttonStyle}>
              Пополнить палет
            </Button>
          </Link>
        </div>
        <div style={{ marginTop: '40px' }}>
          {loading ? (
            <Spin tip="Загрузка..." />
          ) : (
            <Table
              dataSource={productSummary}
              columns={columns}
              rowKey="code"
              pagination={false}
            />
          )}
        </div>
        {selectedPallets && (
          <PalletList
            code={selectedPallets.code}
            size={selectedPallets.size}
            onClose={() => setSelectedPallets(null)}
          />
        )}
      </div>
    </div>
  );
}

export default HomePage;
